import {createSelector} from 'reselect'
import {values} from 'lodash'
import {compareDesc} from 'date-fns'
import { getActive } from 'clubs'

export const getMessages = (state) => state.messages.entities
export const getTemplates = (state) => state.messages.templates
export const getIsFetching = (state) => state.messages.isFetching
export const getMessage = (state, {id}) => state.messages.entities[id]
export const getState = (state) => state.messages
export const getMessageComments = (state, {messageId}) => state.messages.entities[messageId].comments

export const getMessagesArray = createSelector([getMessages], values)
export const getSortedMessages = createSelector(
  [getMessagesArray, getActive],
  (messages, activeId) => {
    
    return messages.filter((message) => message.club ? message.club.id == activeId.id : message.id == activeId.id).sort(({timeStamp: a}, {timeStamp: b}) => compareDesc(a, b))
  }
  
  
)
