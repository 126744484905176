import styled from 'styled-components'
import Input from './Input'
import Flex from './Flex'
import { Cross } from './icons'
import { useEffect, useRef, useState } from 'react'

const Searchbar = styled(Input)`
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-top-left-radius: ${(props) => props.fullyRounded ? '3px' : '0'}; 
        border-bottom-left-radius: ${(props) => props.fullyRounded ? '3px' : '0'};
        width: 300px;
`
const TokenContainer = styled.div`
        background-color: #fff;
        border: solid 2px #86c7e3;
        display: flex;
        gap: 5px;
        height: 48px;
        align-items: center;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        max-width: 200px;
        overflow-x: scroll;
        scroll-behavior: smooth;
        &::-webkit-scrollbar { 
            display: none;
}

`

const SearchbarToken = styled(Flex)`
    justify-content: center; 
    align-items: center;
    gap: 5px;
    background-color: #86c7e3;
    margin: 3px;
    height: 70%;
    padding: 0 5px;
    color: #fff;
    p {
        display: inline;
        white-space: nowrap;
    }
`

const TokenSearchbar = ({query, handleChange, t, tokens, setTokens, setQuery}) => {

    const containerRef = useRef(null);

    useEffect(() => {
        
        if (containerRef.current) {
            containerRef.current.scrollLeft = containerRef.current.scrollWidth;
        }
    }, [tokens]);

    const handleEnter = (event) => {
        if(event.key === 'Enter' && event.target.value.trim() !== ""){
            if(!tokens.includes(event.target.value.trim())){
                setTokens(prev => {
                  const updatedTokens = [...prev, event.target.value];
                  const searchQuery = updatedTokens.join(" ");
                  handleChange(searchQuery); 
                  return updatedTokens;
              });
            }
          setSearchInput("")
        }
      }

      const removeToken = (token) => {
        setTokens(prev => {
            const updatedTokens = prev.filter(item => item !== token);
            const searchQuery = updatedTokens.join(" ");
            handleChange(searchQuery); 
            return updatedTokens;
        });

      };

    const [searchInput, setSearchInput] = useState("")
    return (
        <Flex>
            {tokens.length > 0 && 
                <TokenContainer ref={containerRef}>
                    {
                        tokens.map((token) => <SearchbarToken> <p>{token}</p> <Cross style={{height: "10px", width: "10px", cursor: "pointer", fill: "#fff"}} onClick={() => removeToken(token)}/> </SearchbarToken>)
                    }
                </TokenContainer>}
            <Searchbar               
                fullyRounded={!tokens.length > 0}
                width={200}
                placeholder={`${t('Søg i medlemmer')}...`}
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                onKeyDown={(e) =>  handleEnter(e)}
                >
                
            </Searchbar>
        </Flex>

)
}

export default TokenSearchbar